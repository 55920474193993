import React, { useState } from "react";
import Header from "../../components/Header";
import styled from "styled-components";
import { useFormik } from "formik";
import AccountErrorMessage from "../../components/AccountErrorMessage";
import Snackbar from "@mui/material/Snackbar";
import * as Yup from "yup";
import queryString from "query-string";
import axios from "axios";
import { API_ROOT } from "../../constants";

const Container = styled.div``;

const Form = styled.form`
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 150px;
  font-size: 22px;
  border: 1px solid #000;
  border-radius: 3px;
  padding: 60px;
  position: relative;
  background: #fff;

  * {
    font-size: inherit;
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      color: #222;
    }

    input {
      border: 1px solid rgb(0 0 0 / 90%);
      box-sizing: border-box;
      margin: 3px;
      padding: 3px;
      font-size: 16px;
      line-height: 26px;
      border-radius: 3px;
    }
  }

  button {
    box-sizing: border-box;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 5px 8px;
    align-self: center;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.2s ease;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const initialSnackbarState = {
  isOpen: false,
  message: "",
};

function ResetPassword(props) {
  const [snackbar, setSnackbar] = useState(initialSnackbarState);

  const qs = queryString.parse(props.location.search);

  const handleClose = () => {
    setSnackbar(initialSnackbarState);
  };

  const formik = useFormik({
    initialValues: {
      email: qs.email ?? "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),

    onSubmit: async (values) => {
      axios
        .get(API_ROOT + `users/forgot-password?email=${values.email}`)
        .then(({ data }) => {
          if (data.error) {
            throw new Error(data.message);
          }

          setSnackbar({
            isOpen: true,
            message: data.message,
          });
        })
        .catch((err) => {
          console.error(err.message);
        });

      // * Here is where I want to
    },
  });

  return (
    <Container>
      <Header>Reset Password</Header>
      <Form onSubmit={formik.handleSubmit}>
        <AccountErrorMessage
          touched={formik.touched}
          formikErrors={formik.errors}
        />
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        </div>
        <button type="submit">Submit</button>
      </Form>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar.isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
      />
    </Container>
  );
}

export default ResetPassword;
